import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import {
    addSale,
    clearErrors,
    getClientOptions,
    getProductOptions,
    getSpecialClientMaterialTransactions,
    // getWarehouseOptions
} from "../../actions/sale";
import {Accordion, CloseButton} from "react-bootstrap";
import {ArrowLeft, FilePlus, Plus, X} from "react-feather";
import {Multiselect} from "multiselect-react-dropdown";
import {getSelectedOption} from "../../utils/hooks/getParams";
import moment from "moment";
import SpecialSaleCreateForm from "./components/SpecialSaleCreateForm";
import SimpleSaleCreateForm from "./components/SimpleSaleCreateForm";

const SaleCreateForm = ({
                            client_options,
                            sales,
                            loading,
                            special_client_materials,
                            error,
                            product_options,
                            handleClose,
                            show,
                            getClientOptions,
                            addSale,
                            getProductOptions,
                            getSpecialClientMaterialTransactions,
                        }) => {

    useEffect(() => {
        if (show) {
            getProductOptions({is_for_sale: true});
        }
    }, [show])
    useEffect(() => {
        getProductOptions({is_for_sale: true})
        getClientOptions()
    }, [getProductOptions, getClientOptions]);

    const formRef = useRef();
    const defaultValues = {
        is_special_sale: '',
        client_id: '',
        seller_id: '',
        warehouse_id: '',
        date: '',
        products: []
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };
    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);

    }, [sales]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
        setProductOptions([]);
        setSelectedProductOptions([]);
    };
    useEffect(() => {
        product_options.map(item => {
            item.price = 0;
            item.quantity = 0;
            return item
        });
        setProductOptions(product_options);
    }, [product_options]);

    const [clientOptions, setClientOptions] = useState([]);
    useEffect(() => {
        let options = [];
        client_options?.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setClientOptions(options);
    }, [client_options])
    const [productOptions, setProductOptions] = useState(product_options);
    const [selectedDynamicProductOptions, setSelectedDynamicProductOptions] = useState([]);
    const [selectedProductOptions, setSelectedProductOptions] = useState([]);

    const [isSpecialSale, setIsSpecialSale] = useState(false);


    const onSelectProduct = (data) => {
        setSelectedDynamicProductOptions(data);
    };

    useEffect(() => {
        inputs.is_special_sale = Number(isSpecialSale);
        setInputs(inputs);
        if (isSpecialSale) {
            getClientOptions({with_supplier: true})
        } else {
            getClientOptions()
        }
    }, [isSpecialSale])

    const onRemoveProduct = (data) => {
        setSelectedDynamicProductOptions(data)
    };

    const onClickMoveSelectedMaterials = () => {
        // console.log(selectedDynamicProductOptions)
        if (selectedDynamicProductOptions.length > 0) {
            setSelectedProductOptions([...selectedProductOptions, ...selectedDynamicProductOptions]);
            let ids = selectedDynamicProductOptions.map(({id}) => id);
            const filteredMaterials = productOptions.filter((item) => !ids.includes(item.id));
            setProductOptions(filteredMaterials);
            setSelectedDynamicProductOptions([]);
            const inputProducts = [];
            selectedProductOptions.map(item => inputProducts.push({
                id: item.id,
                quantity: item?.quantity || 0,
                net_price: item?.net_price || 0,
            }));
            inputs.products = [...inputs.products, ...inputProducts];

            setInputs(inputs);
        }
    };

    const [selectedSpecialClient, setSelectedSpecialClient] = useState({});

    const [availableMaterials, setAvailableMaterials] = useState([])
    const [selectedProductsMaterialsCalculations, setSelectedProductsMaterialsCalculations] = useState([]);

    const handleSelect = (data, type) => {

        if (isSpecialSale && type.name == 'client_id') {
            const specialClient = client_options?.find(item => item.id === data.value);
            getSpecialClientMaterialTransactions(data.value);
            setSelectedSpecialClient(specialClient);
        }
        setErrors({});
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };

    const onChangeInput = (e) => {
        let product_id = parseInt(e.target.dataset.id);
        const cleanItems = [];
        const items = selectedProductOptions.map((item) => {
            if (item.id === product_id) {
                if (e.target.getAttribute('name') == 'quantity') {
                    if (e.target.value !== '') {
                        if (Number(e.target.value) > 0) {
                            if (Number(e.target.value) <= item?.in_stock && item?.in_stock !== 0) {
                                item[e.target.getAttribute('name')] = e.target.value;
                            }
                        } else {
                            item[e.target.getAttribute('name')] = 1;
                        }
                    } else {
                        item[e.target.getAttribute('name')] = e.target.value;
                    }
                } else {
                    item[e.target.getAttribute('name')] = e.target.value;
                }

            }
            // cleanItems.push({...item})
            cleanItems.push({
                id: item.id,
                price: item?.price || 0,
                quantity: item?.quantity,
            });
            return item;
        });

        setSelectedProductOptions(items);

        setErrors({});
        setInputs(prevState => ({...prevState, products: [...cleanItems]}));
    };


    const onClickRemoveFromSelectedMaterials = (id) => {
        const filteredSelectedMaterials = selectedProductOptions.filter(item => item.id !== Number(id));
        setSelectedProductOptions([...filteredSelectedMaterials]);
        let ids = filteredSelectedMaterials.map(({id}) => id);
        const filteredMaterials = product_options.filter((item) => !ids.includes(item.id));
        setProductOptions(filteredMaterials);
        setSelectedDynamicProductOptions([]);
    }


    useEffect(() => {

        if (special_client_materials) {
            const items = [];
            special_client_materials?.forEach((item) => {

                    return items.push({
                        id: item.id,
                        material_id: item.material_id,
                        name: item.material?.name,
                        left_amount: item.total_left_amount * Number(item.material?.multiplier),
                        // amount: Number(item.total_left_amount) * Number(item.material?.multiplier),
                        multiplier: item.material?.multiplier,
                        unit: {
                            symbol: item.material?.unit?.symbol,
                        },
                        unit_alternative: {
                            symbol: item.material?.unit_alternative?.symbol,
                        }
                    })
                }
            );
            setAvailableMaterials(items);
        }
    }, [special_client_materials]);

    useEffect(() => {

        if (isSpecialSale) {

            let items = [];
            selectedProductOptions.map(item => item.materials.map((subitem) => {
                subitem.product_id = item.id;
                items.push(subitem)
            }));
            let materialItems = {};
            items.map((item) => {

                if (materialItems[item.material_id]) {
                    const tempProduct = selectedProductOptions.find(product_item => item.product_id == product_item.id);
                    if (tempProduct) {
                        materialItems[item.material_id].amount += Number(item.unit_value) * tempProduct.quantity;
                    }
                } else {
                    materialItems[item.material_id] = item;
                    materialItems[item.material_id].amount = 0;
                    const tempProduct = selectedProductOptions.find(product_item => item.product_id == product_item.id);
                    if (tempProduct) {
                        materialItems[item.material_id].amount = Number(item.unit_value) * tempProduct.quantity;
                    }
                }
            });

            const recalced = JSON.parse(JSON.stringify(availableMaterials)).map(item => {
                const tempMaterial = materialItems[item.material_id];
                if (tempMaterial) {
                    item.left_amount = item.left_amount - tempMaterial.amount;
                }
                return item
            });
            setSelectedProductsMaterialsCalculations(recalced);
        }
    }, [selectedProductOptions])

    const handleMaterialCheckbox = (e) => {
        console.log(e.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
            is_special_sale: inputs?.is_special_sale,
            seller_id: inputs?.supplier_id,
            date: inputs?.date,
            client_id: inputs?.client_id,
            products: inputs.products,
        };
        const response = await addSale(data);
        if (response === true) {
            setInputs(defaultValues);
            setSelectedDynamicProductOptions([]);
            setSelectedProductOptions([]);
            setAvailableMaterials([]);
            setSelectedProductsMaterialsCalculations([]);
            setIsSpecialSale(false);
        } else {
            if (response && response.hasOwnProperty('errors')) {

            }
        }
    };

    useEffect(() => {
        getProductOptions({is_for_sale: true});
        if (error && error.hasOwnProperty('errors')) {
            const errorsItems = error.errors;
            let errorObject = {};
            if (error?.errors) {
                Object.keys(error.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);
        }
    }, [sales])

    return (
        <Fragment>
            <Modal size="lg" show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi sotuv amalga oshirish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">

                    <div className="form-check form-switch">
                        <input className="form-check-input"
                               type="checkbox"
                               id="flexSwitchCheckChecked"
                               onChange={e => setIsSpecialSale(!isSpecialSale)}
                               checked={isSpecialSale}
                        />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                            Maxsus sotuv
                        </label>
                    </div>
                    {
                        isSpecialSale ? (
                            <SpecialSaleCreateForm />
                        ) : (
                            <SimpleSaleCreateForm />
                        )
                    }


                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

SaleCreateForm.propTypes = {
    addSale: PropTypes.func.isRequired,
    getClientOptions: PropTypes.func.isRequired,
    getProductOptions: PropTypes.func.isRequired,
    // getWarehouseOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    sale: state.sale,
    sales: state.sale.sales,
    seller_options: state.sale.seller_options,
    special_client_materials: state.sale.special_client_materials,
    client_options: state.sale.client_options,
    warehouse_options: state.sale.warehouse_options,
    product_options: state.sale.product_options,
    products: state.sale.products,
    loading: state.sale.loading,
    error: state.sale.error,
});


export default connect(mapStateToProps, {
    addSale,
    clearErrors,
    getClientOptions,
    getSpecialClientMaterialTransactions,
    getProductOptions,
    // getWarehouseOptions
})(SaleCreateForm);
